<template>
  <b-card class="store-order-statement">
    <div class="wrap-order-transaction-buttons mt-3">
      <button
        class="btn btn-primary"
        @click="setDate(0)"
      >
        {{ $t('Today') }}
      </button>
      <button
        class="btn btn-primary"
        @click="setDate(-1)"
      >
        {{ $t('Yesterday') }}
      </button>
      <button
        class="btn btn-primary"
        @click="setDate(-7)"
      >
        {{ $t('Current Week') }}
      </button>
      <button
        class="btn btn-primary"
        @click="setDate(-14)"
      >
        {{ $t('Previous Week') }}
      </button>
      <button
        class="btn btn-primary"
        @click="setDate(-30)"
      >
        {{ $t('Current Month') }}
      </button>
      <button
        class="btn btn-primary"
        @click="setDate(-60)"
      >
        {{ $t('Previous Month') }}
      </button>
      <button
        class="btn btn-primary"
        @click="setDate(-365)"
      >
        {{ $t('Current Year') }}
      </button>
      <button
        class="btn btn-primary"
        @click="setDate(-730)"
      >
        {{ $t('Previous Year') }}
      </button>
    </div>
    <div class="filter-dates mt-2">
      <div class="row">
        <div class="col-md-4">
          <label for="datepicker-default">{{ $t('Date From') }}</label>
          <b-form-datepicker
            v-model="filterDates.stateDate"
            local="en"
            class="mb-2"
            today-variant="danger"
          />
        </div>
        <div class="col-md-4">
          <label for="datepicker-default">{{ $t('Date To') }}</label>
          <b-form-datepicker
            v-model="filterDates.endDate"
            local="en"
            class="mb-2"
            today-variant="danger"
          />
        </div>
        <div class="col-md-4 text-left d-flex flex-wrap align-items-center mb-1">
          <button
            class="btn btn-primary"
            @click="filterData"
          >
            {{ $t('Submit') }}
          </button>
        </div>
      </div>
    </div>
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="statementRef"
          :columns="columns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="'/shop/transactions'"
          :extra-params="extraParams"
          :initialize-on-load="false"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BFormDatepicker, BRow, BCol } from 'bootstrap-vue'
import moment from 'moment'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'

export default {
  components: {
    BCard,
    BFormDatepicker,
    BRow,
    BCol,
    GoodDataTable,
  },
  data() {
    return {
      filterDates: {
        stateDate: new Date(),
        endDate: new Date(),
      },
      extraParams: 'transaction_type=store&type=range',
      columns: [
        {
          label: 'Order Number',
          field: 'transaction_alias',
        },
        {
          label: 'Date & Time',
          field: 'dated',
        },
        {
          label: 'Description',
          field: 'transaction_desc',
        },
        {
          label: 'Amount',
          field: 'amount',
          useRenderer: true,
          renderer: props => (props.type === 'C' ? 'Credit' : 'Debit'),
        },
      ],
    }
  },
  mounted() {
    this.filterData()
  },
  methods: {
    setDate(date) {
      if (date === 0) {
        this.filterDates.stateDate = new Date(moment().add('days', date))
        this.filterDates.endDate = new Date(moment().add('days', date))
      } else if (date === -1) {
        this.filterDates.stateDate = new Date(moment().add('days', date))
        this.filterDates.endDate = new Date(moment().add('days', date))
      } else if (date === -7) {
        this.filterDates.stateDate = new Date(moment().startOf('week'))
        this.filterDates.endDate = new Date(moment().endOf('week'))
      } else if (date === -14) {
        this.filterDates.stateDate = new Date(
            moment()
            .startOf('week')
            .add('days', -7),
        )
        this.filterDates.endDate = new Date(
            moment()
            .endOf('week')
            .add('days', -7),
        )
      } else if (date === -30) {
        this.filterDates.stateDate = new Date(moment().startOf('month'))
        this.filterDates.endDate = new Date(moment().endOf('month'))
      } else if (date === -60) {
        const currentDate = new Date()
        this.filterDates.stateDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
        this.filterDates.endDate = new Date(moment(this.filterDates.stateDate).endOf('month'))
      } else if (date === -365) {
        this.filterDates.stateDate = new Date(moment().startOf('year'))
        this.filterDates.endDate = new Date(moment().endOf('year'))
      } else if (date === -730) {
        const currentDate = new Date()
        this.filterDates.stateDate = new Date(currentDate.getFullYear() - 1, 0, 1)
        this.filterDates.endDate = new Date(moment(this.filterDates.stateDate).endOf('year'))
      }
    },
    filterData() {
      const payload = [this.extraParams]
      payload.push(`from=${moment(this.filterDates.stateDate).format('YYYY-MM-DD')}`)
      payload.push(`to=${moment(this.filterDates.endDate).format('YYYY-MM-DD')}`)
      this.$refs.statementRef.loadItems(payload.join('&'))
    },
  },
}
</script>

<style scoped>
.wrap-order-transaction-buttons .btn {
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
@media only screen and (max-width: 767px) {
  .wrap-order-transaction-buttons {
    display: none;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
